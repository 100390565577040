<template>
  <div class="float-bottom">
    <b-alert show v-if="shouldInstallAndroid"
      >Please click the <strong>⋮</strong> icon at the top right, and choose
      <strong>Install app</strong>. If this message remains, click
      <strong>⋮</strong> and choose <strong>Open inspirion</strong>.
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "PromptInstall",

  data() {
    return {
      // From https://erikmartinjordan.com/navigator-platform-deprecated-alternative
      platform:
        navigator?.userAgentData?.platform || navigator?.platform || "unknown",

      // From https://dev.to/oncode/display-your-pwa-website-fullscreen-4776
      standalone:
        // on iOS Safari
        window.navigator.standalone ||
        // on Android Chrome
        window.matchMedia("(display-mode: standalone)").matches,
    };
  },
  computed: {
    isAndroid() {
      return (
        // The crappy Android simulator in chrome
        this.platform.startsWith("Android") ||
        // Real Android reports "Linux ... something something"
        this.platform.startsWith("Linux")
      );
    },
    isIos() {
      // https://javascript.plainenglish.io/creating-a-browser-agnostic-pwa-install-button-41039f312fbe
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    shouldInstallAndroid() {
      return (
        this.isAndroid &&
        window.screen.width < 1000 &&
        !this.standalone & !window.location.href.includes("localhost")
      );
    },
  },

  mounted() {
    // https://www.amitmerchant.com/check-if-pwa-opened-as-standalone-app/
    window.matchMedia("(display-mode: standalone)").addEventListener(
      "change",
      ({ matches }) => {
        this.standalone = matches;
      },
      { passive: true }
    );
  },
};
</script>

<style lang="scss" scoped>
.float-bottom {
  position: fixed;
  bottom: -1em;
  left: 0;
  z-index: 9999;
}
</style>