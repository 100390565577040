<template>
  <div>
    <!-- This never appears on screen.
    It is just a page for the "/" route, but the 
     always shows EITHER the login page OR a relevant logged-in page.-->
  </div>
</template>

<script>
export default {
  mounted() {
    //  this.$router.go(this.$router.currentRoute);
  },
};
</script>
