<template>
  <div
    id="app"
    class="app-container"
    :style="{
      'background-color': currentPageRole === 'teacher' ? '#f0f8ff' : 'white',
    }"
  >
    <AppAutoupdate> </AppAutoupdate>
    <article
      v-if="loggedState === 'in'"
      style="padding-left: 2vw; padding-right: 1vw"
    >
      <transition-page>
        <!-- The ":key" is needed to force a page reload when the questionId changes, in TeacherQuestion, as per Ian Pinto at https://stackoverflow.com/questions/41301099/do-we-have-router-reload-in-vue-router -->
        <router-view :user="user" :key="$route.fullPath" />
      </transition-page>
    </article>

    <article v-if="loggedState === 'out'">
      <Login></Login>
    </article>

    <article
      v-if="!loggedState"
      class="w-100 d-flex flex-column justify-content-center align-items-center"
      style="height: 90vh"
    >
      <!-- Height needs to be in Viewport units because the % gives us % of some small thing. -->
      <img src="@/assets/graduated.png" style="height: 8em" />
      <div style="height: 8em"></div>
      <b-spinner style="height: 8em; width: 8em"> </b-spinner>
    </article>

    <!-- Zero-size component which creates the SVG icon *definitions*, so they can be used  in any components  -->
    <Icons></Icons>
    <PromptInstall></PromptInstall>
  </div>
</template>

<script>
// Loony unit testing in root of app!
// import { unitTest_canonicalDenulledJsonStringify } from "@/utils/equalInTheEyesOfFirebase.mjs";
// unitTest_canonicalDenulledJsonStringify();

import AppAutoupdate from "@/components/AppAutoupdate.vue";
import TransitionPage from "@/components/TransitionPage.vue"; // Visual page transitions
import { auth, db, usersPath, writeUserContactP } from "@/utils/firebase";
import { pageFromRoutePath, roleFromPage } from "@/utils/pagesAndRoutes.js";
import { onAuthStateChanged } from "firebase/auth";
import PromptInstall from "./components/PromptInstall.vue";
const Login = () => import("@/views/Login.vue"); // Only needed if not already logged in, so make it async.
const Icons = () => import("@/components/Icons.vue"); // Not a disaster if they appear later, so make it async

export default {
  components: {
    TransitionPage,
    Login,
    Icons,
    AppAutoupdate,
    PromptInstall,
  },
  data() {
    return {
      loggedState: undefined,
      user: {
        id: null,
        fromAuth: {}, // We will copy in the USER object from Auth, into here.
        // Things to get from Firebase:
        contact: {},
        permissions: {},
        progress: {},
        progressRef: {}, // Because student needs to be able to write to it
        role: "", // "teacher" or "student"
      },
      moduleSummaries: {},
      modules: {},
      questions: {},
      showIconsComponent: false,
    };
  },

  computed: {
    isStudent() {
      return true;
    },
    isCreator() {
      return this.user.permissions.isCreator;
    },
    isHq() {
      return this.user.permissions.isHq;
    },

    currentRoutePage() {
      return pageFromRoutePath(this.$route.path); //
    },

    currentPageRole() {
      return roleFromPage(this.currentRoutePage);
    },
  },
  created() {
    onAuthStateChanged(auth, this.updateLoggedStateP);
  },

  mounted() {},

  methods: {
    async updateLoggedStateP(userFromAuth) {
      // Only at this point is this.$route set up correctly with path, params, query and hash.

      if (userFromAuth) {
        this.user.fromAuth = userFromAuth;
        this.user.id = userFromAuth.uid;

        Promise.all([
          db
            .ref(usersPath + this.user.id + "/contactRW/emailReadonly")
            .set(userFromAuth.email),
          // For current user
          this.$rtdbBind(
            "user.contact",
            db.ref(usersPath + this.user.id + "/contactRW")
          ),
          this.$rtdbBind(
            "user.permissions",
            db.ref(usersPath + this.user.id + "/permissionsR")
          ),
          (this.user.progressRef = db.ref(
            usersPath + this.user.id + "/progressRW"
          )),
          this.$rtdbBind("user.progress", this.user.progressRef),
        ]).then(() => {
          this.loggedState = "in";
          this.overrideUrlPathIfNecessary(this.user, this.$route.path);
        });
      } else {
        this.loggedState = "out";
      }
    },

    /* EXAMPLES
      http://localhost:8080/sa/aca~med
      http://localhost:8080/sc/aca~med/cou~fyc-imp-1
      http://localhost:8080/sm/aca~med/cou~fyc-imp-1/-KqE3gWYNyoZESBp6dR2
      http://localhost:8080/sm/aca~med/cou~fyc-imp-1/mod~-MJb190ivUIw4sq0QTMY
      http://localhost:8080/ta/aca~med
      http://localhost:8080/tc/aca~med/cou~fyc-imp-1
      http://localhost:8080/tm/aca~med/cou~fyc-imp-1/-KqE3gWYNyoZESBp6dR2
      http://localhost:8080/tm/aca~med/cou~fyc-imp-1/mod~-MJb190ivUIw4sq0QTMY
      http://localhost:8080/tq/aca~med/cou~fyc-imp-1/-KqE3gWYNyoZESBp6dR2/-KqE3mygHhOFMdSLFvGV
*/
    overrideUrlPathIfNecessary(user, path) {
      const isTeacher = user.contact && user.contact.role === "teacher";
      if (!isTeacher) {
        // So the "role" gets the value "student" written into it by default
        writeUserContactP(user.id, "role", "student");
      }

      // Now see if the current path passed on the URL is a legitimate one (and permissible for this user, and not one that is banned because of needing extra parameters)
      const pathRoot4Char = (path || "").slice(0, 4);
      const validModuleFromPathRootStudent = {
        "/sa/": "studentAcademy",
        "/sc/": "studentCourse",
        "/sm/": "studentModule",
        "/cyp": "cypressEmptyView",
      };
      const validModuleFromPathRootTeacher = {
        ...validModuleFromPathRootStudent,
        "/ta/": "teacherAcademy",
        "/tc/": "teacherCourse",
        "/tm/": "teacherModule",
        "/tq/": "teacherQuestion",
        "/cyp": "cypressEmptyView",
      };
      const validModuleFromPathRoot = isTeacher
        ? validModuleFromPathRootTeacher
        : validModuleFromPathRootStudent;

      // If this person is not entitled to go to the path, then send them to a default path (which is the default path for all students)

      const validModuleName = validModuleFromPathRoot[pathRoot4Char];

      if (!validModuleName) {
        // No direct path, e.g. if pasted in a silly link, or one to teacherQuestion which is banned for deep linking
        this.$router.replace({ name: "student" });
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Open+Sans|PT+Sans+Narrow|Source+Serif+Pro&display=swap");

// SOURCE SERIF is referenced by name inside TipTap.vue

.app-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow-wrap: break-word; // To stop URLs going off the end of a page
}

.narrow-font {
  font-size: 90%;
  font-family: "PT Sans Narrow", "Arial Narrow", "Open Sans", sans-serif;
}

// Undoing crazy ionic defaults
.text-input-ios {
  margin: 0;
  padding: 0.2em;
  width: calc(100% - 0px);
  // Default is:
  //  margin: 10px 8px 10px 0;
  //  padding: 0;
  //  width: calc(100% - 8px);
}

// General design stuff
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

// Parts of the page
// .preamble {
// }

// .question {
// }

.option {
  background-position: right; // This is so that the tick or cross doesn't have to float across the screen;
  transition: all 2s ease;
}

.clarification {
  font-size: 80%;
}

.add-block {
  margin-top: 0.5em;
  margin-bottom: 1em;
  padding: 0.5em;
  border-radius: 0.5em;
  border: 0.5em;
  background-color: #f0f0f0;
}

.question-segment-heading {
  font-size: 150%;
  color: darkgray;
}
.question-segment-subheading {
  font-size: 80%;
  color: darkgray;
}

// sliding transition between pages
.slide-container {
  overflow: hidden;
}

.slide-reference-position {
  // Use this to reverse any of the below. For some unknown reason removing them does not allow a smooth transition (when we put the transition in the container above, which should allow it
  transform: translate(0, 0) scale(1);
  transition: all 500ms ease-in;
}

.slide-left {
  transform: translate(-100vw, 0);
  transition: all 500ms ease-in;
}

.slide-right {
  transform: translate(100vw, 0);
  transition: all 500ms ease-in;
}

.slide-up {
  transform: translate(0, -100vh);
  transition: all 500ms ease-in;
}

.slide-down {
  transform: translate(0, 100vh);
  transition: all 500ms ease-in;
}

.slide-expand {
  overflow: visible;
  z-index: 100000;
  background-color: white;
  transform: scale(1.5);
  transform-origin: left center;
  transition: all 500ms ease-in;
}

.slide-shrink {
  transform: scale(0.1);
  transform-origin: top center;
  transition: all 500ms ease-in;
}

// Accordion for divs
// You must manually set the max-height of the container, in the HTML. e.g.
// <div class="accordion-container" style="max-height:4em;">
// Also note that this accordion is not compatible with ion-item: can't be an ion-item (or even part of one, I think)
.accordion-container {
  overflow: hidden;
  transition: 0.5s ease-in-out;
}

.accordion-collapsed {
  max-height: 0 !important;
  color: transparent !important;
  background-color: transparent !important;
}

// A row of blocks that show a big number (or big graph) and  under it, a title and subtitle

.info-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f0f0f0;
}

.info-block {
  background-color: white;
  border-radius: 1em;
  flex-basis: 4.5em;
  flex-grow: 1;
  max-width: 7em;
  height: 7em;
  margin: 0.2em;
}

.info-block-number {
  text-align: center;
  font-size: 200%;
}

.info-block-title {
  text-align: center;
  font-size: 80%;
}

.info-block-subtitle {
  text-align: center;
  font-size: 60%;
}

.info-block-highlighted {
  background-color: mix(#ffb000, white, 15%);
}

// Training (teachers)
.training-block {
  color: #005eb9;
  background-color: #c0dcf8;
  padding: 1em;
  // color: color($colors, primary);
  // background-color: mix(color($colors, primary), white, 10%);
}

// varieties of Option: note that the delays here are only to the COLORIZATIONS
.option-megabutton {
  // before selection, show student as buttons, so that it is obvious to click
  background-color: #f0f0f0;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 1em;
  margin-bottom: 0.5em;
  // min-width: 50vw;
  // max-width: 30em;
  // max-height: 30em;
}

.spaced-like-option-megabutton {
  // this is to help stabilise the options' position once the megabutton class is removed
  margin-bottom: 0.5em;
}

.option-selected {
  // But not yet say right or wrong
  background-color: #7c7c7c;
  color: white;
}

.option-selected-right {
  color: white;
  background-color: #005eb9;
  background-image: url("./assets/tick.svg");
  background-repeat: no-repeat;
  background-position: 95% 50%;
  background-size: 2.5em;
}

.option-selected-wrong {
  background-repeat: no-repeat; // iphone seems to need multiple copies of this
  animation-name: keyframes-selected-wrong;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes keyframes-selected-wrong {
  0% {
    background: none right; // the "right" is here to stop the cross being animated
    background-repeat: no-repeat;
  }
  100% {
    background-color: #ffa53d;
    background-image: url("./assets/cross.svg");
    background-repeat: no-repeat;
    background-position: 95% 50%;
    background-size: 2.5em;
  }
}

.option-unselected {
  background-color: #c0c0c0;
}

.option-this-would-have-been-the-right-answer {
  animation-name: keyframes-this-would-have-been-the-right-answer;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes keyframes-this-would-have-been-the-right-answer {
  0% {
    background-color: none;
  }
  100% {
    background-color: #4ba4fd;
  }
}

// Delayed reveal of things after scoring
.reveal-clarification-selected {
  // What you have clicked.
  overflow: hidden;
  animation-name: keyframes-reveal-delay;
  animation-duration: 10s; // This time is fake, relevant only if the maximum amount of content (1000 lines of text) is present. // Long so that we can fix on a large max-height. In reality will take about 2 seconds max, assuming a maximum of 20 lines of stuff to reveal
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.reveal-clarification-would-be-correct {
  overflow: hidden;
  animation-name: keyframes-reveal-delay;
  animation-duration: 10s; // This time is fake, relevant only if the maximum amount of content (1000 lines of text) is present.
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.reveal-clarification-other {
  overflow: hidden;
  animation-name: keyframes-reveal-delay;
  animation-duration: 20s; // This time is fake, relevant only if the maximum amount of content (1000 lines of text) is present.
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.reveal-bottom-comments-and-stars {
  overflow: hidden;
  animation-name: keyframes-reveal-delay;
  animation-duration: 10s; // This time is fake, relevant only if the maximum amount of content (1000 lines of text) is present.
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes keyframes-reveal-delay {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 1000em; // Hope they don't have anything bigger! 1000 lines is a lot, let's face it.
  }
}

// Information round-rects and histograms for star ratings

// Histogram stuff
.histo-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.histo-pic {
  display: flex;
  flex-direction: column;
}

.histo-top-pseudobar {
  background-color: transparent;
  height: 0.1em;
}

.histo-bar {
  background-color: #ffb000;
  height: 0.5em;
  margin: 0.05em;
}

.histo-bottom-pseudobar {
  background-color: transparent;
  height: 0.1em;
}

.histo-number {
  text-align: center;
  flex-basis: 50%;
  font-size: 200%;
}

.error {
  background-color: darkred;
  color: white;
}

//Video
.full-screen-video {
  video#backgroundvid {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    background-size: cover;
  }
}

// GENERAL

.link {
  font-size: smaller;
  color: royalblue;
}

/* general utility classes for project */

.ellipsis-must-set-width {
  /* apply this class to a div to set ellipsis truncation but you MUST set a width on the element, and the text mUST be the immediate contents of the DIV, not of children.
  A good way to set the width is
    width: calc( 80% - 32px or whatever);
    The space before/after the "-" is mandatory
  */
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

//

.swish-enter,
.swish-leave-to {
  opacity: 0;
  max-height: 0;
}

.swish-leave,
.swish-enter-to {
  opacity: 1;
  max-height: 1000vh;
}

.swish-enter-active {
  transition: max-height 1s, opacity 2s;
}

.swish-leave-active {
  transition: max-height 1s, opacity 2s;
}

//

.swish-fast-enter,
.swish-fast-leave-to {
  opacity: 0;
  max-height: 0;
}

.swish-fast-leave,
.swish-fast-enter-to {
  opacity: 1;
  max-height: 1000vh;
}

.swish-fast-enter-active {
  transition: max-height 0.1s, opacity 0.2s;
}

.swish-fast-leave-active {
  transition: max-height 1s, opacity 1s;
}

//

.swish-2d-enter,
.swish-2d-leave-to {
  opacity: 0;
  max-height: 0;
  max-width: 0;
}
.swish-2d-leave,
.swish-2d-enter-to {
  opacity: 1;
  max-height: 100vh;
  max-width: 100vw;
}

.swish-2d-enter-active {
  transition: max-height 1s, max-width 1s, opacity 2s;
}

.swish-2d-leave-active {
  transition: max-height 1s, max-width 1s, opacity 2s;
}

// -------------------------------

.blinking {
  animation: blinking-keyframes 1s linear infinite;
}

@keyframes blinking-keyframes {
  50% {
    opacity: 0;
  }
}

.instruction {
  color: gray;
  line-height: 1.1;
  font-size: 80%;
}

.unavailable {
  color: lightgray;
}

.scrolling-section {
  height: 100%;
  overflow-y: auto;
  padding-right: 2px;
}

// I found out that this stuff has to be in the "global" space, not just in the ".scrolling-section"

::-webkit-scrollbar {
  width: 10px;
  height: 10px; // In case a sideways scroll is ever needed
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: lightgray;
}

// JIGGLE
// https://www.w3schools.com/howto/howto_css_shake_image.asp

.jiggle {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 2s;
  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
  cursor: ns-resize;
}

@keyframes shake {
  0% {
    transform: translate(0.5px, 0.5px) rotate(0deg);
  }
  10% {
    transform: translate(-0.5px, -1px) rotate(-0.3deg);
  }
  20% {
    transform: translate(-0.3px, 0px) rotate(0.3deg);
  }
  30% {
    transform: translate(0.3px, 1px) rotate(0deg);
  }
  40% {
    transform: translate(0.5px, -0.5px) rotate(0.3deg);
  }
  50% {
    transform: translate(-0.5px, 1px) rotate(-0.3deg);
  }
  60% {
    transform: translate(-0.3px, 0.5px) rotate(0deg);
  }
  70% {
    transform: translate(0.3px, 0.5px) rotate(-0.3deg);
  }
  80% {
    transform: translate(-0.5px, -0.5px) rotate(0.3deg);
  }
  90% {
    transform: translate(0.5px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(0.5px, -1px) rotate(-0.3deg);
  }
}

.multimedia-summary-slug-container {
  margin: 0 0.5em 1em 0.5em;
  padding: 0 !important;
  overflow: hidden;
  // border-radius: 0.4em !important; // Important needed because the enclosing <b-list-group-item> sets a small (~0.2em) rounding to the top of the first and bottom of the last item
  border-radius: 0.5em;
  border: 1px solid lightgray;
  box-shadow: 0em 0.2em 0.5em gray;
}

.box-shadow {
  box-shadow: 0em 0.2em 0.5em black;
}

.cursor-pointer {
  cursor: pointer;
}

.multimedia-slug-image-rounder {
  border-radius: 0.4em;
}

.n-new-comments-bullet {
  color: black;
  border: 1px solid black;
  border-radius: 0.5em;
  margin-left: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
}
</style>
