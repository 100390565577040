/* eslint-disable chai-friendly/no-unused-expressions */

export function pageFromRoutePath(routePath /* = this.$route.path */) {
  // eslint-disable-next-line no-useless-escape
  const matchResult = routePath.match(/\/([^\/]+)\//);

  // For a path "/xyz/abc/def/......", extract the "xyz" bit, into the [1] item of the result. Match gives a messy result that is part array and part object.
  if (matchResult && matchResult.length >= 2) {
    return matchResult[1];
  } else {
    return null;
  }
}

export function roleFromPage(page /* output of pageFromRoutePath  */) {
  const isStudentPage = ["student", "sa", "sc", "sm"].includes(page);
  const isTeacherPage = ["teacher", "ta", "tc", "tm", "tq"].includes(page);

  if (isStudentPage) return "student";
  if (isTeacherPage) return "teacher";
  return null;
}
