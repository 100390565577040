import Vue from "vue";

// @ts-ignore
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";

// BootstrapVue
import "./custom.scss"; // To THEME it, we need to import bootstrap & bootstrap-vue INSIDE our custom theme, and make sure to use the SCSS version of bootstrap

import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);

// Firebase
import { rtdbPlugin } from "vuefire";
Vue.use(rtdbPlugin);

// Vue Moment https://www.npmjs.com/package/vue-moment
Vue.use(require("vue-moment"));

import "./registerServiceWorker";

// Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
