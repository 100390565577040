/* eslint-disable chai-friendly/no-unused-expressions */
import { db, usersPath } from "./firebase.js";
// import "./getCommonFromFunctionsFolder.js";
// import { codeFirebase } from "./getCommonFromFunctionsFolder.js";

// calculate       codeFirebase("a!£$ %^&* ()[];',.<>/?~@:'");

export const LUDICROUSLY_SMALL_SPACE = "\u200A";

export function joinPathSlash(pathFragments = ["", ""]) {
  // Convert array of path segment strings to a single string that can be used in DB.OBJECT or DB.LIST in Firebase
  //
  // Each string gets any TERMINAL slash(es) removed. All resulting NON-EMPTY de-slashed strings are joined together, with each having a slash appended.
  // Note that NON-TERMINAL slashes are left unaffected so you can send in paths containing internal slashes, e.g. containing multiple firebase segments, or an "http://abc.c". However do not send in a segment called "http://" otherwise the slashes will be deleted.
  //
  // Example
  // ["a/b","c","/","d"] => "a/b/c/d/"
  // ["a//","b/","c","///"] => "a/b/c/"

  let result = "";
  pathFragments.forEach((fragment) => {
    const strippedFragment = (fragment || "").replace(/\/+$/, "");
    if (strippedFragment.length > 0) {
      result += `${strippedFragment}/`;
    }
  });
  return result;
}

export function objToArray(obj = {}) {
  return Object.values(obj || {});
}

export function objToArrayWithDotKey(obj = {}) {
  return Object.keys(obj || {}).map((key) => ({
    ...obj[key],
    ".key": key,
  }));
}

export function objToOrderedKeyedArray(obj = {}) {
  const arr = objToArrayWithDotKey(obj);
  return arr.sort((itemA, itemB) => {
    return (itemA.order || 0) - (itemB.order || 0);
  });
}

// Delay via a promise. See
// https://gist.github.com/joepie91/2664c85a744e6bd0629c
//
// Usage:
// Promise.resolve()
//   .then(this.global.delayMs(1000))
//   .then(result => ... );

export function delayMs(timeDelayMs = 0) {
  return (anyResultToPassThrough) =>
    new Promise((resolve) =>
      setTimeout(() => resolve(anyResultToPassThrough), timeDelayMs)
    );
}

// https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript
// Answer of user3158327
// const  myRandomFunction = createRandomGeneratorWithSeed(1234);
// const  randomNumber = myRandomFunction();
export function createRandomGeneratorWithSeed(seed = 0) {
  const mask = 0xffffffff;
  let m_w = (123456789 + seed) & mask;
  let m_z = (987654321 - seed) & mask;
  return function () {
    m_z = (36969 * (m_z & 65535) + (m_z >>> 16)) & mask;
    m_w = (18000 * (m_w & 65535) + (m_w >>> 16)) & mask;
    let result = ((m_z << 16) + (m_w & 65535)) >>> 0;
    result /= 4294967296;
    return result;
  };
}

export function hash(s = "") {
  // Just a simple hash to produce a NUMBER to seed the randomization of the options! Used only in STUDENT MODULE
  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    let char = s.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

import cryptojs from "crypto-js";
export function getSha1HexFromString(str) {
  // SHA1 used to create cloud filename for images
  return cryptojs.SHA1(str);
}

async function getContactObjectP(userUid = "") {
  const snapshot = await db
    .ref(joinPathSlash([usersPath, userUid, "contactRW"]))
    .once("value");
  const contactObject = snapshot.val();
  return contactObject;
}

export async function getUserNameOrEmailP(userUid = "") {
  const contactObject = await getContactObjectP(userUid);
  if (contactObject?.fullName) {
    return contactObject.fullName;
  }
  if (contactObject?.emailReadonly) {
    return contactObject.emailReadonly;
  }
  return "Unknown user";
}

// export function copyFirebaseTree(
//   sourcePath = "/nonExistentSource",
//   destinationPath = "/nonExistentSource",
//   byWhom = "uid"
// ) {
//   let that = this;
//   return new Promise((outerResolve, outerReject) => {
//     that.db
//       .object(sourcePath)
//       .take(1)
//       .subscribe(chunkObj => {
//         let chunk = chunkObj.$value || {};
//         that.db
//           .object(destinationPath)
//           .update(chunk)
//           .then(result => {
//             that.toastController
//               .create({ message: "Copied", duration: 3000 })
//               .present();
//           })
//           .catch(error => {
//             that.toastController
//               .create({ message: "Sorry: " + error.message, duration: 5000 })
//               .present();
//           });
//       });
//   });
// }

export function moveFirebaseTreeP(
  sourcePath = "/dummy/path/to/source",
  destinationPath = "/dummy/path/to/destination",
  byWhom = "uid-will-come-here"
) {
  return db.ref(sourcePath).once("value", (snapshot) => {
    const chunk = snapshot.val() || {};
    // Make a note of who deleted it and when
    chunk.deletedBy = byWhom || "";
    chunk.tDeleted = +new Date();
    db.ref(destinationPath)
      .update(chunk)
      .then(() => db.ref(sourcePath).remove());
  });
}

export function shadeBlendConvert(proportion, c0, c1, l) {
  // Version 4. Originally used version 3 from Stack Overflow but then in Dec 2019 I moved to the version 4 from Github. https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)
  // CHANGES: I have changed "this.pSBCr" to a local constant pSBCr
  let r,
    g,
    b,
    a,
    isDarkening,
    darkeningProportion,
    c0rgba,
    c1rgba,
    rgbDecimalFormat,
    mathRound = Math.round,
    c1IsString = typeof c1 == "string" ? 1 : 0;

  if (
    typeof proportion != "number" ||
    proportion < -1 ||
    proportion > 1 ||
    typeof c0 != "string" ||
    (c0[0] != "r" && c0[0] != "#") ||
    (c1 && !c1IsString)
  )
    return null;

  const toRGBA = (colorString) => {
    let colorStringLength = colorString.length,
      rgba = {};
    if (colorStringLength > 9) {
      ([r, g, b, a] = colorString = colorString.split(",")),
        (colorStringLength = colorString.length);
      if (colorStringLength < 3 || colorStringLength > 4) return null;
      (rgba.r = parseInt(r[3] == "a" ? r.slice(5) : r.slice(4))),
        (rgba.g = parseInt(g)),
        (rgba.b = parseInt(b)),
        (rgba.a = a ? parseFloat("" + a) : -1);
    } else {
      if (
        colorStringLength == 8 ||
        colorStringLength == 6 ||
        colorStringLength < 4
      )
        return null;
      if (colorStringLength < 6)
        colorString =
          "#" +
          colorString[1] +
          colorString[1] +
          colorString[2] +
          colorString[2] +
          colorString[3] +
          colorString[3] +
          (colorStringLength > 4 ? colorString[4] + colorString[4] : "");
      colorString = parseInt(colorString.slice(1), 16);
      if (colorStringLength == 9 || colorStringLength == 5)
        (rgba.r = (colorString >> 24) & 255),
          (rgba.g = (colorString >> 16) & 255),
          (rgba.b = (colorString >> 8) & 255),
          (rgba.a = mathRound((colorString & 255) / 0.255) / 1000);
      else
        (rgba.r = colorString >> 16),
          (rgba.g = (colorString >> 8) & 255),
          (rgba.b = colorString & 255),
          (rgba.a = -1);
    }
    return rgba;
  };
  rgbDecimalFormat = c0.length > 9;
  rgbDecimalFormat = c1IsString
    ? c1.length > 9
      ? true
      : c1 == "c"
      ? !rgbDecimalFormat
      : false
    : rgbDecimalFormat;
  c0rgba = toRGBA(c0);
  isDarkening = proportion < 0;
  c1rgba =
    c1 && c1 != "c"
      ? toRGBA(c1)
      : isDarkening
      ? {
          r: 0,
          g: 0,
          b: 0,
          a: -1,
        }
      : {
          r: 255,
          g: 255,
          b: 255,
          a: -1,
        };
  proportion = isDarkening ? proportion * -1 : proportion;
  darkeningProportion = 1 - proportion;
  if (!c0rgba || !c1rgba) return null;
  if (l) {
    r = mathRound(darkeningProportion * c0rgba.r + proportion * c1rgba.r);
    g = mathRound(darkeningProportion * c0rgba.g + proportion * c1rgba.g);
    b = mathRound(darkeningProportion * c0rgba.b + proportion * c1rgba.b);
  } else {
    r = mathRound(
      (darkeningProportion * c0rgba.r ** 2 + proportion * c1rgba.r ** 2) ** 0.5
    );
    g = mathRound(
      (darkeningProportion * c0rgba.g ** 2 + proportion * c1rgba.g ** 2) ** 0.5
    );
    b = mathRound(
      (darkeningProportion * c0rgba.b ** 2 + proportion * c1rgba.b ** 2) ** 0.5
    );
  }
  a = c0rgba.a;
  c1rgba = c1rgba.a;
  c0rgba = a >= 0 || c1rgba >= 0;
  a = c0rgba
    ? a < 0
      ? c1rgba
      : c1rgba < 0
      ? a
      : a * darkeningProportion + c1rgba * proportion
    : 0;
  if (rgbDecimalFormat)
    return (
      "rgb" +
      (c0rgba ? "a(" : "(") +
      r +
      "," +
      g +
      "," +
      b +
      (c0rgba ? "," + mathRound(a * 1000) / 1000 : "") +
      ")"
    );
  else
    return (
      "#" +
      (
        4294967296 +
        r * 16777216 +
        g * 65536 +
        b * 256 +
        (c0rgba ? mathRound(a * 255) : 0)
      )
        .toString(16)
        .slice(1, c0rgba ? undefined : -2)
    );
}

// export function removeStopWords(source = "") {
//   let cleansed_string = source;
//   let regex_str = "";
//   // Split out all the individual words in the phrase
//   const words = source.match(/[^\s]+|\s+[^\s+]$/g);
//   //let timeSTART = +new Date();
//   // Review all the words
//   const nStopWords = stop_words.length;
//   for (let x = 0; x < words.length; x++) {
//     // For each word, check all the stop words
//     for (let y = 0; y < nStopWords; y++) {
//       // Get the current word
//       const word = words[x].replace(/\s+|[^a-z]+/gi, ""); // Trim the word and remove non-alpha
//
//       // Get the stop word
//       const stop_word = this.stop_words[y];
//
//       // If the word matches the stop word, remove it from the keywords
//       if (word.toLowerCase() === stop_word) {
//         // Build the regex
//         regex_str = "^\\s*" + stop_word + "\\s*$"; // Only word
//         regex_str += "|^\\s*" + stop_word + "\\s+"; // First word
//         regex_str += "|\\s+" + stop_word + "\\s*$"; // Last word
//         regex_str += "|\\s+" + stop_word + "\\s+"; // Word somewhere in the middle
//         const regex = new RegExp(regex_str, "ig");
//
//         // Remove the word from the keywords
//         cleansed_string = cleansed_string.replace(regex, " ");
//       }
//     }
//   }
//
//   return cleansed_string;
// }

// Constant list of stop words

// const stop_words = [
//   "a",
//   "about",
//   "above",
//   "across",
//   "after",
//   "again",
//   "against",
//   "all",
//   "almost",
//   "alone",
//   "along",
//   "already",
//   "also",
//   "although",
//   "always",
//   "among",
//   "an",
//   "and",
//   "another",
//   "any",
//   "anybody",
//   "anyone",
//   "anything",
//   "anywhere",
//   "are",
//   "area",
//   "areas",
//   "around",
//   "as",
//   "ask",
//   "asked",
//   "asking",
//   "asks",
//   "at",
//   "away",
//   "b",
//   "back",
//   "backed",
//   "backing",
//   "backs",
//   "be",
//   "became",
//   "because",
//   "become",
//   "becomes",
//   "been",
//   "before",
//   "began",
//   "behind",
//   "being",
//   "beings",
//   "best",
//   "better",
//   "between",
//   "big",
//   "both",
//   "but",
//   "by",
//   "c",
//   "came",
//   "can",
//   "cannot",
//   "case",
//   "cases",
//   "certain",
//   "certainly",
//   "clear",
//   "clearly",
//   "come",
//   "could",
//   "d",
//   "did",
//   "differ",
//   "different",
//   "differently",
//   "do",
//   "does",
//   "done",
//   "down",
//   "down",
//   "downed",
//   "downing",
//   "downs",
//   "during",
//   "e",
//   "each",
//   "early",
//   "either",
//   "end",
//   "ended",
//   "ending",
//   "ends",
//   "enough",
//   "even",
//   "evenly",
//   "ever",
//   "every",
//   "everybody",
//   "everyone",
//   "everything",
//   "everywhere",
//   "f",
//   "face",
//   "faces",
//   "fact",
//   "facts",
//   "far",
//   "felt",
//   "few",
//   "find",
//   "finds",
//   "first",
//   "for",
//   "four",
//   "from",
//   "full",
//   "fully",
//   "further",
//   "furthered",
//   "furthering",
//   "furthers",
//   "g",
//   "gave",
//   "general",
//   "generally",
//   "get",
//   "gets",
//   "give",
//   "given",
//   "gives",
//   "go",
//   "going",
//   "good",
//   "goods",
//   "got",
//   "great",
//   "greater",
//   "greatest",
//   "group",
//   "grouped",
//   "grouping",
//   "groups",
//   "h",
//   "had",
//   "has",
//   "have",
//   "having",
//   "he",
//   "her",
//   "here",
//   "herself",
//   "high",
//   "high",
//   "high",
//   "higher",
//   "highest",
//   "him",
//   "himself",
//   "his",
//   "how",
//   "however",
//   "i",
//   "if",
//   "important",
//   "in",
//   "interest",
//   "interested",
//   "interesting",
//   "interests",
//   "into",
//   "is",
//   "it",
//   "its",
//   "itself",
//   "j",
//   "just",
//   "k",
//   "keep",
//   "keeps",
//   "kind",
//   "knew",
//   "know",
//   "known",
//   "knows",
//   "l",
//   "large",
//   "largely",
//   "last",
//   "later",
//   "latest",
//   "least",
//   "less",
//   "let",
//   "lets",
//   "like",
//   "likely",
//   "long",
//   "longer",
//   "longest",
//   "m",
//   "made",
//   "make",
//   "making",
//   "man",
//   "many",
//   "may",
//   "me",
//   "member",
//   "members",
//   "men",
//   "might",
//   "more",
//   "most",
//   "mostly",
//   "mr",
//   "mrs",
//   "much",
//   "must",
//   "my",
//   "myself",
//   "n",
//   "necessary",
//   "need",
//   "needed",
//   "needing",
//   "needs",
//   "never",
//   "new",
//   "new",
//   "newer",
//   "newest",
//   "next",
//   "no",
//   "nobody",
//   "non",
//   "noone",
//   "not",
//   "nothing",
//   "now",
//   "nowhere",
//   "number",
//   "numbers",
//   "o",
//   "of",
//   "off",
//   "often",
//   "old",
//   "older",
//   "oldest",
//   "on",
//   "once",
//   "one",
//   "only",
//   "open",
//   "opened",
//   "opening",
//   "opens",
//   "or",
//   "order",
//   "ordered",
//   "ordering",
//   "orders",
//   "other",
//   "others",
//   "our",
//   "out",
//   "over",
//   "p",
//   "part",
//   "parted",
//   "parting",
//   "parts",
//   "per",
//   "perhaps",
//   "place",
//   "places",
//   "point",
//   "pointed",
//   "pointing",
//   "points",
//   "possible",
//   "present",
//   "presented",
//   "presenting",
//   "presents",
//   "problem",
//   "problems",
//   "put",
//   "puts",
//   "q",
//   "quite",
//   "r",
//   "rather",
//   "really",
//   "right",
//   "right",
//   "room",
//   "rooms",
//   "s",
//   "said",
//   "same",
//   "saw",
//   "say",
//   "says",
//   "second",
//   "seconds",
//   "see",
//   "seem",
//   "seemed",
//   "seeming",
//   "seems",
//   "sees",
//   "several",
//   "shall",
//   "she",
//   "should",
//   "show",
//   "showed",
//   "showing",
//   "shows",
//   "side",
//   "sides",
//   "since",
//   "small",
//   "smaller",
//   "smallest",
//   "so",
//   "some",
//   "somebody",
//   "someone",
//   "something",
//   "somewhere",
//   "state",
//   "states",
//   "still",
//   "still",
//   "such",
//   "sure",
//   "t",
//   "take",
//   "taken",
//   "than",
//   "that",
//   "the",
//   "their",
//   "them",
//   "then",
//   "there",
//   "therefore",
//   "these",
//   "they",
//   "thing",
//   "things",
//   "think",
//   "thinks",
//   "this",
//   "those",
//   "though",
//   "thought",
//   "thoughts",
//   "three",
//   "through",
//   "thus",
//   "to",
//   "today",
//   "together",
//   "too",
//   "took",
//   "toward",
//   "turn",
//   "turned",
//   "turning",
//   "turns",
//   "two",
//   "u",
//   "under",
//   "until",
//   "up",
//   "upon",
//   "us",
//   "use",
//   "used",
//   "uses",
//   "v",
//   "very",
//   "w",
//   "want",
//   "wanted",
//   "wanting",
//   "wants",
//   "was",
//   "way",
//   "ways",
//   "we",
//   "well",
//   "wells",
//   "went",
//   "were",
//   "what",
//   "when",
//   "where",
//   "whether",
//   "which",
//   "while",
//   "who",
//   "whole",
//   "whose",
//   "why",
//   "will",
//   "with",
//   "within",
//   "without",
//   "work",
//   "worked",
//   "working",
//   "works",
//   "would",
//   "x",
//   "y",
//   "year",
//   "years",
//   "yet",
//   "you",
//   "young",
//   "younger",
//   "youngest",
//   "your",
//   "yours",
//   "z",
// ];
