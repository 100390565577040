// Load the firebase namespace along with all its type information (but no actual code)
import firebase from "firebase/compat/app"; // Cannot go to the modular, until ALL parts go to the modular. The compat sets up firebase for BOTH the modular and non-modular ("/compat/") services for auth, database and storage. DATABASE is the sticking point as we are waiting for VueFire to upgrade to Firebase API v9 (As of Jan 2022, not doing it yet)

// Load individual services
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import "firebase/compat/database"; // CANNOT move this to the new format yet, because VueFire is not yet upgraded to Firebase API v9
import {
  getStorage,
  ref as firebaseCloudStorageRefFunction /* to disambiguate from the 'ref' of database */,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

import { getSha1HexFromString } from "./general.js";

const firebaseConfig = {
  apiKey: "AIzaSyCDkJ3vGgHTkbUsW2Ab_GbHHzmeMzsFwq8",
  authDomain: "inspirion.org",
  // authDomain: "inspirion-33fec.firebaseapp.com",
  databaseURL: "https://inspirion-33fec.firebaseio.com",
  projectId: "inspirion-33fec",
  storageBucket: "inspirion-33fec.appspot.com",
  messagingSenderId: "605205424042",
  appId: "1:605205424042:web:1b2d3ec07e4e51b352add3",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Same bucket has two access URLs
const BUCKET_ADDRESS_VIA_FIREBASE_CLOUD = `firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o`; // Old way. Has a TOKEN suffix which is bad because (1) reuploading same image gets new token, so old image will no longer display, (2) browser can't cache
const BUCKET_ADDRESS_VIA_GOOGLE_CLOUD = `${firebaseConfig.storageBucket}.storage.googleapis.com`; // New way
const UNSPLASH_ADDRESS = "source.unsplash.com/";
const UNITY_MAGIQUANT_ADDRESS = "files.magiquant.com/";
const UNITY_GOOGLEAPIS_ADDRESS = "storage.googleapis.com/scantensus/";
const ADDRESSES = [
  BUCKET_ADDRESS_VIA_FIREBASE_CLOUD,
  BUCKET_ADDRESS_VIA_GOOGLE_CLOUD,
  UNSPLASH_ADDRESS,
  UNITY_MAGIQUANT_ADDRESS,
  UNITY_GOOGLEAPIS_ADDRESS,
];

const HTTP = "http://";
const HTTPS = "https://";
const TRANSPORTS = [HTTP, HTTPS];

export const VALID_IMAGE_STORAGE_URL_PREFIXES = [
  ...TRANSPORTS.map((transport) =>
    ADDRESSES.map((address) => `${transport}${address}`)
  ),
].flat();

/*
 
   _____        _        _                    
  |  __ \      | |      | |                   
  | |  | | __ _| |_ __ _| |__   __ _ ___  ___ 
  | |  | |/ _` | __/ _` | '_ \ / _` / __|/ _ \
  | |__| | (_| | || (_| | |_) | (_| \__ \  __/
  |_____/ \__,_|\__\__,_|_.__/ \__,_|___/\___|
                                              
                                              
 
*/

export const db = firebaseApp.database();
export const firebaseDatabaseServerValue = firebase.database.ServerValue; // NOTHING to do with firebase*App*.database*()*
export const systemStatusRef = db.ref("systemStatus");
export const auth = getAuth(firebaseApp);
export const provider = new GoogleAuthProvider();

const firebaseRoot = "/Inspirion";

export const usersPath = `${firebaseRoot}/users/`;
export const questionsPath = `${firebaseRoot}/questions/`;

export const firstGuessesPath = `${firebaseRoot}/questionFirstGuesses/`;
export const firstGuessesHistoPath = `${firebaseRoot}/questionFirstGuessesHisto/`;
export const firstGuessesTimelinePath = `${firebaseRoot}/questionFirstGuessesTimeline/`;

export const mostRecentGuessesPath = `${firebaseRoot}/questionMostRecentGuesses/`;
export const mostRecentGuessesHistoPath = `${firebaseRoot}/questionMostRecentGuessesHisto/`;
export const mostRecentGuessesTimelinePath = `${firebaseRoot}/questionMostRecentGuessesTimeline/`;

export const starsPath = `${firebaseRoot}/questionStars/`;
export const commentsPath = `${firebaseRoot}/questionComments/`;
export const progressPath = `${firebaseRoot}/moduleProgress/`;
export const nNewAcaCouModQueComsPath = `${firebaseRoot}/newAcaCouModQueComs/`;
export const activityDACMPath = `${firebaseRoot}/activityDACM/`;
export const activityACMDPath = `${firebaseRoot}/activityACMD/`;
// HIERARCHY

// Academy
export const academySummariesPath = `${firebaseRoot}/academySummaries/`;
export const oneAcademyPathF = (academyId) =>
  `${firebaseRoot}/academies/${academyId}/`;
export const oneAcademyCourseSummariesPathF = (academyId) =>
  `${oneAcademyPathF(academyId)}courseSummaries/`;

// Course
export const oneCoursePathF = (academyId, courseId) =>
  `${firebaseRoot}/courses/${courseId}/`;
export const oneCourseModuleSummariesPathF = (academyId, courseId) =>
  `${oneCoursePathF(academyId, courseId)}moduleSummaries/`;

// Module
export const modulesPath = `${firebaseRoot}/modules/`;
export const oneModulePathF = (moduleId) =>
  `${firebaseRoot}/modules/${moduleId}/`;

// export const moduleSummariesRoot = moduleSummariesRootF("echo", "intro"); // TEMPORARY UNTIL WE REPLACE WITH INDIVIDUALISED, VARIABLE
// export const moduleSummariesRef = db.ref(moduleSummariesRoot);

export function writeUserP(userId, pathWithinUser, value) {
  return db.ref(usersPath).child(userId).child(pathWithinUser).set(value);
}

export function writeUserContactP(userId, pathWithinContact, value) {
  return db
    .ref(usersPath)
    .child(userId)
    .child("contactRW")
    .child(pathWithinContact)
    .set(value);
}

/*
 
    _____ _                 _    _____ _                             
   / ____| |               | |  / ____| |                            
  | |    | | ___  _   _  __| | | (___ | |_ ___  _ __ __ _  __ _  ___ 
  | |    | |/ _ \| | | |/ _` |  \___ \| __/ _ \| '__/ _` |/ _` |/ _ \
  | |____| | (_) | |_| | (_| |  ____) | || (_) | | | (_| | (_| |  __/
   \_____|_|\___/ \__,_|\__,_| |_____/ \__\___/|_|  \__,_|\__, |\___|
                                                           __/ |     
                                                          |___/      
 
*/

const storageRootRef = getStorage();
const assetsRootRef = firebaseCloudStorageRefFunction(storageRootRef, "assets"); // Images
// const cardsRootRef = storageRootRef.child("cards"); // Version 1 of Twitter links, accessed from "cards.inspirion.org", but looked silly as "cards.inspirion.org/cards", and also had a long URL with multiple firebase push Ids in it
const hashesRootRef = firebaseCloudStorageRefFunction(storageRootRef, "h2"); // Version 2 of Twitter links

const imagesUploaded = {}; // This will be used to store the status of recently-uploaded images

// From "CUIXIPING" answer at https://stackoverflow.com/questions/16968945/convert-base64-png-data-to-javascript-file-objects?noredirect=1&lq=1
// Return a promise that resolves with a File instance
function dataUriToFileP(url, filename, mimeType) {
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
  return fetch(url) // It's not going to the internet, it is just reading the existing dataURI
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }));
}

export async function uploadImageUrlIfNotAlreadyP(imageUrl) {
  const imageUrlHash = getSha1HexFromString(imageUrl);
  if (Object.keys(imagesUploaded).includes(imageUrlHash)) {
    const state = imagesUploaded[imageUrlHash].state;
    if (state === "success") {
      return imagesUploaded[imageUrlHash].cloudUrl;
    }
  }

  // New image, or state is "failed" etc
  const cloudStorageRef = firebaseCloudStorageRefFunction(
    assetsRootRef,
    `${imageUrlHash}.png`
  );
  imagesUploaded[imageUrlHash] = { state: "waiting" };

  return dataUriToFileP(imageUrl, imageUrlHash, "image/png")
    .then((fileObject) => uploadBytes(cloudStorageRef, fileObject))
    .then((/* ignore snapshot */) => getDownloadURL(cloudStorageRef))
    .then((firebaseDownloadUrl) => {
      const googleCloudDownloadUrl =
        convertFirebaseCloudStorageUrlToGoogleCloudStorageUrl(
          firebaseDownloadUrl
        );

      imagesUploaded[imageUrlHash] = {
        state: "success",
        cloudUrl: googleCloudDownloadUrl,
      };
      return googleCloudDownloadUrl;
    })
    .catch(() => {
      imagesUploaded[imageUrlHash].state = "failed";
    });
}

export async function uploadStaticPageToBucketP(
  pathInHashFolderOfBucket,
  textOfFile
) {
  const DUMMY_NAME_THAT_DOES_NOT_MATTER =
    "Static_page_for_an_Inspirion_module_or_course_etc";
  const cloudStorageRef = firebaseCloudStorageRefFunction(
    hashesRootRef,
    pathInHashFolderOfBucket
  );
  const fileObject = new File([textOfFile], DUMMY_NAME_THAT_DOES_NOT_MATTER, {
    type: "text/html",
  });

  return uploadBytes(cloudStorageRef, fileObject)
    .then((/* ignore snapshot */) => getDownloadURL(cloudStorageRef))
    .then((downloadUrl) => ({
      state: "success",
      cloudUrl: downloadUrl,
    }))
    .catch(() => ({
      state: "failed",
    }));
}

export function isFirebaseCloudStorageUrl(url) {
  // This is the old URL to access it from, which requires a different token per upload, causing multiply-used images to break. And can't be cached.
  return (
    url.trim().startsWith(HTTPS + BUCKET_ADDRESS_VIA_FIREBASE_CLOUD) ||
    url.trim().startsWith(HTTP + BUCKET_ADDRESS_VIA_FIREBASE_CLOUD)
  );
}

export function convertFirebaseCloudStorageUrlToGoogleCloudStorageUrl(
  firebaseUrl
) {
  const withoutPrefix = firebaseUrl.replace(
    HTTPS + BUCKET_ADDRESS_VIA_FIREBASE_CLOUD,
    ""
  );
  const withoutPrefixOrSuffix = withoutPrefix.slice(
    0,
    withoutPrefix.indexOf("?")
  );
  const googleCloudUrl =
    HTTPS + BUCKET_ADDRESS_VIA_GOOGLE_CLOUD + withoutPrefixOrSuffix;

  return googleCloudUrl;
}
