import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home.vue";
import Student from "@/views/Student.vue";

Vue.use(VueRouter);

// SLIDERPATH
// This is a variable I made up and added to the TransitionPage component, that creates a virtual path used ONLY by TransitionPage's slider logic to work out which way to slide the page. This is needed because most of the pages have shallow actual URL paths, and I don't want users to see detailed paths in the URL. So instead we create a hidden path which is never shown. The ONLY processing done on sliderPath is counting of "[L]","[R]","[U]","[D]","[I]", and "[O]" symbols. Doesn't matter what the other characters are: just for ease of programmer memory.

const routes = [
  // Deep Linking is only allowed for very specific paths,
  // laid out in App.js under "validModuleFromPathRootStudent"
  // and "validModuleFromPathRootTeacher"

  {
    path: "/",
    name: "home",
    component: Home,
    props: true,
    meta: {
      transitionName: "slide",
      sliderPath: "",
      gracePeriodMinutesBeforeAutoUpdate: 0,
    },
  },

  {
    path: "/welcome",
    name: "student",
    component: Student,
    props: true,
    meta: {
      transitionName: "slide",
      sliderPath: "",
      gracePeriodMinutesBeforeAutoUpdate: 0,
    },
  },
  {
    path: "/enter-academy",
    name: "chooseAcademy",
    component: () =>
      import(/* webpackChunkName: "ca" */ "@/views/ChooseAcademy.vue"),
    props: true,
    meta: {
      transitionName: "slide",
      sliderPath: "",
      gracePeriodMinutesBeforeAutoUpdate: 0,
    },
  },
  {
    path: "/sa/:academyId",
    name: "studentAcademy",
    component: () =>
      import(/* webpackChunkName: "sa" */ "@/views/StudentAcademy.vue"),
    props: true,
    meta: {
      transitionName: "slide",
      sliderPath: "[R]",
      gracePeriodMinutesBeforeAutoUpdate: 0,
    },
  },
  {
    path: "/sc/:academyId/:courseId/",
    name: "studentCourse",
    component: () =>
      import(/* webpackChunkName: "sc" */ "@/views/StudentCourse.vue"),
    props: true,
    meta: {
      transitionName: "slide",
      sliderPath: "[R] [R] StudentCourse",
      gracePeriodMinutesBeforeAutoUpdate: 0,
    },
  },
  {
    path: "/sm/:academyId/:courseId/:moduleId",
    name: "studentModule",
    component: () =>
      import(/* webpackChunkName: "sm" */ "@/views/StudentModule.vue"),
    props: true,
    meta: {
      transitionName: "slide",
      sliderPath: "[R] [R] StudentAcademy [R] studentModule",
      gracePeriodMinutesBeforeAutoUpdate: 30,
    },
  },
  {
    path: "/sm/:academyId/:courseId/:moduleId/:questionId",
    // Happens when a TEACHER tweets a QUESTION, and the student clicks on the link. The student will be taken to the whole module, not the question itself.
    name: "studentModuleWithDummyQuestionId",
    component: () =>
      import(/* webpackChunkName: "sm_dq" */ "@/views/StudentModule.vue"),
    props: true,
    meta: {
      transitionName: "slide",
      sliderPath: "[R] [R] StudentAcademy [R] studentModule",
      gracePeriodMinutesBeforeAutoUpdate: 30,
    },
  },
  {
    path: "/teacher",
    name: "teacher",
    component: () => import(/* webpackChunkName: "t" */ "@/views/Teacher.vue"),
    props: true,
    meta: {
      transitionName: "slide",
      sliderPath: "[U] ",
      gracePeriodMinutesBeforeAutoUpdate: 0,
    },
  },
  {
    path: "/ta/:academyId",
    name: "teacherAcademy",
    component: () =>
      import(/* webpackChunkName: "ta" */ "@/views/TeacherAcademy.vue"),
    props: true,
    meta: {
      transitionName: "slide",
      sliderPath: "[U] [R]",
      gracePeriodMinutesBeforeAutoUpdate: 30,
    },
  },
  {
    path: "/tc/:academyId/:courseId",
    name: "teacherCourse",
    component: () =>
      import(/* webpackChunkName: "tc" */ "@/views/TeacherCourse.vue"),
    props: true,
    meta: {
      transitionName: "slide",
      sliderPath: "[U] [R] [R]",
      gracePeriodMinutesBeforeAutoUpdate: 30,
    },
  },

  {
    path: "/tm/:academyId/:courseId/:moduleId",
    name: "teacherModule",
    component: () =>
      import(/* webpackChunkName: "tm" */ "@/views/TeacherModule.vue"),
    props: true,
    meta: {
      transitionName: "slide",
      sliderPath: "[U] [R] [R] [R]",
      gracePeriodMinutesBeforeAutoUpdate: 30,
    },
  },

  {
    path: "/tq/:academyId/:courseId/:moduleId/:questionId",
    name: "teacherQuestion",
    component: () =>
      import(/* webpackChunkName: "tq" */ "@/views/TeacherQuestion.vue"),
    props: true,
    meta: {
      transitionName: "slide",
      sliderPath: "[U] [R] [R] [R] [R]",
      gracePeriodMinutesBeforeAutoUpdate: 30,
    },
  },

  {
    path: "/cypress-empty-view",
    name: "cypressEmptyView",
    component: () =>
      import(/* webpackChunkName: "empty" */ "@/views/CypressEmptyView.vue"),
    props: true,
    meta: {
      transitionName: "slide",
      sliderPath: "[U] [R] [R] [R] [R]",
      gracePeriodMinutesBeforeAutoUpdate: 0,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
