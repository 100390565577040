<template>
  <div style="height: 100vh" class="d-flex flex-column justify-content-between">
    <!-- <app-header-bar></app-header-bar> -->

    <section
      id="middleScrolling"
      class="scrolling-section iphone-scroll-fudge"
      style="height: 100%; overflow-y: auto"
    ></section>

    <section
      id="bottomBar"
      class="mt-2 d-flex justify-content-between"
      style="flex-shrink: 0"
    ></section>
  </div>
</template>

<script>
import {
  db,
  academySummariesPath,
  writeUserContactP,
} from "@/utils/firebase.js";

export default {
  name: "Student",
  components: {},
  props: { user: { type: Object, required: true } },
  data() {
    return {
      academySummaries: {},
      // Making global consts and functions available to THIS
    };
  },

  computed: {
    currentAcademyId() {
      if (
        this.user &&
        this.user.contact &&
        this.user.contact.currentAcademyId
      ) {
        return this.user.contact.currentAcademyId;
      } else {
        return null;
      }
    },
  },

  mounted() {
    writeUserContactP(this.user.id, "role", "student");
    this.$rtdbBind("academySummaries", db.ref(academySummariesPath)).then(
      () => {
        if (
          Object.keys(this.academySummaries).includes(
            this.user.contact.currentAcademyId
          )
        ) {
          this.$router.replace({
            name: "studentAcademy",
            params: {
              academyId: this.user.contact.currentAcademyId,
            },
          });
        } else {
          this.$router.push({ name: "chooseAcademy" });
        }
      }
    );
  },

  methods: {
    //
    //  ______ _          _
    //  |  ___(_)        | |
    //  | |_   _ _ __ ___| |__   __ _ ___  ___
    //  |  _| | | '__/ _ \ '_ \ / _` / __|/ _ \
    //  | |   | | | |  __/ |_) | (_| \__ \  __/
    //  \_|   |_|_|  \___|_.__/ \__,_|___/\___|
    //

    //
    //   _   _                            _   _
    //  | | | |                          | | (_)
    //  | | | |___  ___ _ __    __ _  ___| |_ _  ___  _ __
    //  | | | / __|/ _ \ '__|  / _` |/ __| __| |/ _ \| '_ \
    //  | |_| \__ \  __/ |    | (_| | (__| |_| | (_) | | | |
    //   \___/|___/\___|_|     \__,_|\___|\__|_|\___/|_| |_|
    //

    //
    //  ______                         _
    //  |  ___|                       | |
    //  | |_ ___  _ __ _ __ ___   __ _| |_
    //  |  _/ _ \| '__| '_ ` _ \ / _` | __|
    //  | || (_) | |  | | | | | | (_| | |_
    //  \_| \___/|_|  |_| |_| |_|\__,_|\__|
    //

    //   _   _             _             _   _
    //  | \ | |           (_)           | | (_)
    //  |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    //  | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    //  | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    //  \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                        __/ |
    //                       |___/

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
</style>