<template>
  <div>
    <!-- During debugging, we have a second instance of the "AppAutoupdate" component, just to show the status at the bottom of home pages. It doesn't produce a yellow warning but (as it so happens) it does cause its own auto-updates -->
    <div
      v-if="onlyShowCopyright"
      class
      style="text-align: center; font-size: 50%; color: darkgray"
    >
      (C) Inspirion 2017-2022. Status code
      <!-- {{ runningVersion.slice(12).replace(/:/g, "") }}&nbsp;:&nbsp;{{
        latestVersion.slice(12).replace(/:/g, "")
      }} -->
    </div>

    <div v-if="!onlyShowCopyright">
      <b-alert
        show
        v-if="runningVersionRelative() > 0"
        variant="warning"
        style="position: absolute; opacity: 0.85; width: 100%"
        class="px-4 py-1"
      >
        <h6 style="text-align: center">Being built and deployed...</h6>
      </b-alert>

      <b-alert
        show
        v-if="runningVersionRelative() < 0"
        variant="warning"
        style="position: absolute; opacity: 0.8; width: 100%; z-index: 1000"
        class="px-4 py-1"
      >
        <div v-if="aboutToAutoUpdateNow">
          <br />
          <br />
          <h1 style="text-align: center">Updating app</h1>
          <br />
          <br />
        </div>
        <div v-else>
          <h5 style="text-align: center; font-size: 71%">
            App updating in
            {{ $route.meta.gracePeriodMinutesBeforeAutoUpdate }} minutes
            <b-button
              @click="warnAndDoAutoUpdate"
              variant="warning"
              size="sm"
              class="ml-2 py-0 px-2"
              >Update now</b-button
            >
          </h5>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { BUILDVERSION } from "@/utils/buildVersion.js"; // This is overwritten by the building process

import {
  systemStatusRef, // just in the global space, non- "this."
} from "@/utils/firebase.js";

import { getNewest } from "@/utils/getNewest.js"; // but remember to also re-export it in methods

export default {
  props: {
    onlyShowCopyright: { type: Boolean, required: false, default: false },
  },

  data: function () {
    return {
      aboutToAutoUpdateNow: false, // Will write to this once have $ROUTE and LATESTVERSION
      runningVersion: BUILDVERSION,
      latestVersion: "",
      latestVersionRead: false,
    };
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    $route: function (routeObj) {
      this.autoUpdateIfNewVersionAvail(true);
    },
  },

  created() {
    systemStatusRef.child("latestVersion").on("value", (snapshot) => {
      this.latestVersion = snapshot.val() || null;
      this.latestVersionRead = true;
      this.autoUpdateIfNewVersionAvail(false);
    });
  },

  methods: {
    runningVersionRelative: function () {
      if (this.latestVersionRead) {
        if (this.runningVersion > this.latestVersion) {
          return +1; // The version running is NEWER than the one listed on firebase, which will happen if you have half-run the build script and then aborted it or (while it runs) are serving it locally via NPM RUN DEV.
        } else if (this.runningVersion < this.latestVersion) {
          return -1; // The version running is OLDER than the one listed on firebase, which means you should update
        } else {
          return 0; // You are up to date
        }
      }
      return undefined;
    },

    autoUpdateIfNewVersionAvail(canUpdateImmediately = false) {
      const graceMinutes =
        this.$route.meta.gracePeriodMinutesBeforeAutoUpdate || 0;
      if (this.runningVersionRelative() < 0) {
        this.autoUpdateAfterGraceMinutes(
          canUpdateImmediately ? 0 : graceMinutes
        );
      }
    },

    autoUpdateAfterGraceMinutes(gracePeriodMinutes) {
      setTimeout(this.warnAndDoAutoUpdate, gracePeriodMinutes * 60 * 1000);
    },
    warnAndDoAutoUpdate() {
      this.aboutToAutoUpdateNow = true;
      setTimeout(getNewest, 500);
    },

    //
    getNewest, // Copy from GLOBAL (imported area) to THIS, so the TEMPLATE can use this in @click
  },
};
</script>

<style>
</style>
